<template>
  <section ref="container"
           class="w-full py-12 overflow-x-hidden md:py-24"
  >
    <Container v-if="title">
      <h2 class="text-2xl md:text-[56px] font-medium text-white md:text-center leading-[1.2] mb-6 lg:b-12">
        {{ title }}
      </h2>
    </Container>

    <Container v-if="showCategories && categories?.length > 0">
      <SectionBlogListingCategories :categories="categories"
                                    :query-param-category="queryParamCategory"
                                    :query-param-page="queryParamPage"
                                    :selected="selectedCategory"
      />
    </Container>

    <GridCardImage class="mt-6 lg:mt-12"
                   :cards="entryCards.slice((page - 1) * itemsToShow, (page - 1) * itemsToShow + itemsToShow)"
                   :mobile-cards-as-slider="!pagination"
    />

    <Container>
      <div v-if="cta"
           class="flex mt-8 md:mt-16 md:justify-center"
      >
        <ButtonBase :label="cta.title"
                    :href="cta.href"
                    :type="cta.type"
        />
      </div>

      <SectionBlogListingPagination v-if="pagination"
                                    class="mt-8 md:mt-16"
                                    :query-param="queryParamPage"
                                    :total-items="entryCards.length"
      />
    </Container>
  </section>
</template>

<script setup lang="ts">
/**
 * Collection (blog) listing component
 *
 * This component can be used to render items from a given collection.
 *
 * It can be used as a pagination resource (with pagination set to true) or as a component
 * displaying the latest 6 or 9 items.
 *
 * If used a as a pagination resource it is recommended to not use the mobile slider
 * option for a better user experience.
 *
 * It should work with multiple components on the same page, that's why the page
 * query param and the category query param are configurable (with sensible defaults)
 * as they have to be unique on each page.
 */
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import type { CtaType } from '../../models/button'
import { useAsyncData, queryContent } from '#imports'
import type { BlogCollection } from '~/constants'
import type { Category } from '~/models/blog'

type BlogListingProps = {
  title?: string
  itemsToShow: number
  collections: Array<BlogCollection>
  showCategories: boolean
  pagination: boolean
  cta: CtaType
  queryParamPage: string
  queryParamCategory: string
}

const props = withDefaults(defineProps<BlogListingProps>(), {
  queryParamPage: 'page',
  queryParamCategory: 'category'
})

const route = useRoute()
const page = ref(route.query[props.queryParamPage] ? Number(route.query[props.queryParamPage]) : 1)
const container = ref<HTMLElement | null>(null)

const selectedCategory = computed(() => {
  if (route.query[props.queryParamCategory]) {
    return route.query[props.queryParamCategory] as string
  }
  return ''
})

const collectionsData = (await Promise.all(props.collections.map(collection => {
  return useAsyncData(`${collection}-listing`, () => {
    return queryContent(collection).sort({ date: -1 }).only(['card', 'categories', 'date']).find()
  })
}))).flatMap(collection => collection.data?.value)

const categories = getCategories()

const entryCards = computed(() => {
  return collectionsData
    .filter(entry => {
      if (!selectedCategory.value) {
        return true
      }
      return entry.categories.some((category: Category) => category.slug === selectedCategory.value)
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .map(entry => entry.card)
})

watch(
  () => route.query[props.queryParamPage],
  () => {
    page.value = route.query[props.queryParamPage] ? Number(route.query[props.queryParamPage]) : 1

    // Scroll into view next tick
    nextTick(() => {
      if (container.value) {
        container.value.scrollIntoView(true)
      }
    })
  }
)

function getCategories() {
  if (!props.showCategories) {
    return []
  }

  // Flatten categories from all collections and remove duplicates
  return collectionsData.reduce((categoriesAcc, entry) => {
    if (!entry.categories) return

    entry.categories.forEach((category: Category) => {
      if (categoriesAcc.some(c => c.slug === category.slug)) return
      categoriesAcc.push(category)
    })

    return categoriesAcc
  }, [])
}
</script>
